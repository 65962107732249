








































import { defineComponent, computed } from '@nuxtjs/composition-api';
import { SfLink, SfImage } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  name: 'SocialList',
  components: {
    SfLink,
    SvgImage,
    SfImage,
  },
  props: {
    fill: {
      type: String,
      default: 'white',
    },
  },
  setup(props) {
    const isFillBlack = computed(() => props.fill === 'black');
    return {
      isFillBlack,
      addBasePath,
    };
  },
  data() {
    return {
      social: [
        {
          id: 1,
          icon: 'ins',
          link: 'https://www.instagram.com/unineeduk/',
        },
        {
          id: 2,
          icon: 'facebook',
          link: 'https://www.facebook.com/Unineedcom/',
        },
        {
          id: 3,
          icon: 'youtube',
          link: 'https://www.youtube.com/channel/UCKUGUs1BR3dE7chEBpPK_xg',
        },
        {
          id: 4,
          icon: 'tiktok',
          link: 'https://www.tiktok.com/@unineed',
        },
        {
          id: 5,
          icon: 'pinterest',
          link: 'https://www.pinterest.co.uk/unineed',
        },
        {
          id: 6,
          icon: 'red',
          link: 'https://www.xiaohongshu.com/user/profile/5e9fa3e1000000000100ba5e',
        },
      ],
    };
  },
});
