import {
  useRouter, useContext, ref,
} from '@nuxtjs/composition-api';
import type { Product } from '~/modules/catalog/product/types';
import useCart from '~/modules/checkout/composables/useCart';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import useUiNotification from '~/composables/useUiNotification';

export const useAddToCart = () => {
  const {
    addItem: addItemToCartBase,
    isInCart,
    error,
  } = useCart();
  const router = useRouter();
  const { app, $config } = useContext();
  const { getProductPath } = useProduct();
  const loading = ref<boolean>(false);
  const { send: sendNotification } = useUiNotification();

  const addItemToCart = async (params: { product: Product, quantity: number }) => {
    const { product, quantity } = params;
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    const productType = product.__typename;
    loading.value = true;
    switch (productType) {
      case 'XsearchProduct':
      case 'SimpleProduct':
        await addItemToCartBase({
          product,
          quantity,
        });
        loading.value = false;
        break;
      case 'BundleProduct':
      case 'ConfigurableProduct':
      case 'GroupedProduct':
        const path = app.localeRoute(getProductPath(product));

        await router.push(path);
        loading.value = false;
        break;
      default:
        loading.value = false;
        throw new Error(`Product Type ${productType} not supported in add to cart yet`);
    }
    if (error.value && error.value.addItem) {
      let strMsg = error.value.addItem.toString();
      if (strMsg.includes('is not available') && $config.currentSite === 'cn') {
        strMsg = '抱歉，该商品因缺货暂无法添加到购物车中';
      }
      sendNotification({
        id: Symbol('add_to_cart_failed'),
        message: strMsg as string,
        type: 'danger',
        icon: 'info',
        persist: false,
        title: 'Error Happened',
      });
    } else {
      sendNotification({
        id: Symbol('add_to_cart_success'),
        message: app.i18n.t('Add To Cart Successfully') as string,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'congratulations',
      });
    }
  };

  return {
    addItemToCart,
    isInCart,
    loading,
  };
};
