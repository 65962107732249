



































































































import {
  SfHeading,
  SfButton,
  SfProductCard,
  SfLoader,
  SfBreadcrumbs,
  SfPrice,
} from '@storefront-ui/vue';
import {
  computed,
  defineComponent,
  onMounted,
  useContext,
  ref,
} from '@nuxtjs/composition-api';
import { useUiState, useImage, useProduct } from '~/composables';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import EmptyWishlist from '~/modules/wishlist/components/EmptyWishlist.vue';
import { Breadcrumb } from '~/modules/catalog/types';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useAddToCart } from '~/helpers/cart/addToCart';
import type { WishlistItemInterface } from '~/modules/GraphQL/types';
import SocialList from '~/components/SocialList.vue';

export default defineComponent({
  name: 'WishlistSidebar',
  components: {
    SfButton,
    SfBreadcrumbs,
    SfHeading,
    SfProductCard,
    SfLoader,
    EmptyWishlist,
    SfPrice,
    SocialList,
  },
  setup() {
    const {
      localePath,
      app: { i18n },
    } = useContext();
    const breadcrumbs = ref<Breadcrumb[]>([
      {
        text: i18n.t('Home') as string,
        link: localePath('/'),
      },
      {
        text: 'Wish',
        link: localePath('/wish'),
      },
    ]);
    const { isWishlistSidebarOpen, toggleWishlistSidebar } = useUiState();
    const { addItemToCart, isInCart } = useAddToCart();
    const { getProductPath } = useProduct();
    const {
      removeItem,
      load: loadWishlist,
      loading,
      isInWishlist,
      addOrRemoveItem,
    } = useWishlist();
    const wishlistStore = useWishlistStore();
    const wishlistItems = computed<WishlistItemInterface[]>(
      () => wishlistStore.wishlist?.items_v2?.items ?? []
    );
    const totalItems = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    const { getMagentoImage, imageSizes } = useImage();

    onMounted(async () => {
      await loadWishlist({ searchParams: { pageSize: 100 } });
    });

    const addItemToWishlist = async (product) => {
      await addOrRemoveItem({ product });
    };

    return {
      breadcrumbs,
      isWishlistSidebarOpen,
      wishlistItems,
      removeItem,
      toggleWishlistSidebar,
      totalItems,
      wishlist: wishlistStore.wishlist,
      getMagentoImage,
      imageSizes,
      loading,
      productGetters,
      addItemToCart,
      isInCart,
      isInWishlist,
      addOrRemoveItem,
      getProductPath,
      addItemToWishlist,
    };
  },
});
