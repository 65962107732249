var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"layout-container",attrs:{"id":"wish"}},[_c('SfBreadcrumbs',{staticClass:"breadcrumbs",attrs:{"breadcrumbs":_vm.breadcrumbs,"data-testid":"breadcrumbs"}}),_vm._v(" "),_c('SfLoader',{staticClass:"loading-block",attrs:{"loading":_vm.loading}},[(_vm.totalItems)?_c('div',{key:"my-wishlist",staticClass:"my-wishlist"},[_c('div',{staticClass:"wish-head"},[_c('SfHeading',{staticClass:"sf-heading__title",attrs:{"level":3},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"wish-title"},[_vm._v("\n              "+_vm._s(_vm.$t('Your WishList'))+"\n              "),_c('span',{staticClass:"wish-count"},[_vm._v("("+_vm._s(_vm.totalItems)+")")])])]},proxy:true}],null,false,2167573487)}),_vm._v(" "),_c('div',{staticClass:"desktop-only"},[_c('SocialList',{attrs:{"fill":"black"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"collected-product-list"},_vm._l((_vm.wishlistItems),function(wishlistItem,itemKey){return _c('SfProductCard',{key:itemKey,staticClass:"collected-product",attrs:{"image":_vm.getMagentoImage(
              _vm.productGetters.getProductThumbnailImage(wishlistItem.product)
            ),"image-tag":"nuxt-img","title":_vm.productGetters.getName(wishlistItem.product),"image-width":_vm.imageSizes.productCard.width,"image-height":_vm.imageSizes.productCard.height,"nuxt-img-config":{
            fit: 'cover',
          },"regular-price":_vm.$fc(_vm.productGetters.getPrice(wishlistItem.product).regular),"special-price":_vm.productGetters.getPrice(wishlistItem.product).special &&
            _vm.$fc(_vm.productGetters.getPrice(wishlistItem.product).special),"link":_vm.localePath(_vm.getProductPath(wishlistItem.product)),"max-rating":5,"score-rating":_vm.productGetters.getAverageRating(wishlistItem.product),"reviews-count":_vm.productGetters.getTotalReviews(wishlistItem.product),"is-in-wishlist":_vm.isInWishlist({ product: wishlistItem.product }),"is-added-to-cart":_vm.isInCart(wishlistItem.product)},on:{"click:wishlist":function($event){return _vm.addItemToWishlist(wishlistItem.product)},"click:add-to-cart":function($event){return _vm.addItemToCart({ product: wishlistItem.product, quantity: 1 })},"click:remove":function($event){return _vm.removeItem({ product: wishlistItem.product })}},scopedSlots:_vm._u([{key:"price",fn:function(){return [_c('SfPrice',{staticClass:"sf-product-card__price flex-center",attrs:{"regular":_vm.$fc(_vm.productGetters.getPrice(wishlistItem.product).regular),"special":_vm.productGetters.getPrice(wishlistItem.product).special &&
                _vm.$fc(_vm.productGetters.getPrice(wishlistItem.product).special)}}),_vm._v(" "),_c('div',{staticClass:"sf-add-to-cart"},[_c('SfButton',{staticClass:"sf-button--pure sf-add-to-cart__outlined",on:{"click":function($event){return _vm.addItemToCart({
                    product: wishlistItem.product,
                    quantity: 1,
                  })}}},[_vm._v("\n                "+_vm._s(_vm.$t('Add to bag'))+"\n              ")])],1)]},proxy:true},{key:"wishlist-icon",fn:function(){return [_c('span',{staticClass:"wish-remove-icon"},[_vm._v(_vm._s(_vm.$t('Remove')))])]},proxy:true}],null,true)})}),1)]):_c('EmptyWishlist')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }